<template>
    <div class="warehouseClassify">
        仓库分类
    </div>
</template>
    
<script>
export default {
    data() {
        return {

        }
    },
}
</script>
    
<style></style>